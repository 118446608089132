<template>
  <div class="block">
    <el-page-header @back="dynamic" content="用车记录" style="margin-bottom: 15px;">
    </el-page-header>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-button type="" icon="el-icon-upload" @click="outerVisible = true"
                 style="margin-right: 10px;font-size: 30px;padding-top: 5px;padding-bottom: 5px;
                 padding-right: 10px;padding-left: 10px;color: #2c3e50">
      </el-button>
      <el-dialog title="上传" :visible.sync="outerVisible">
        <el-upload
            class="upload-demo"
            ref="upload"
            action="/api/veh/impVehList"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :auto-upload="false"
            style="width: 50%">
          <el-button slot="trigger" size="mini" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload">上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过500kb</div>
        </el-upload>
        <div slot="footer" class="dialog-footer">
          <el-link type="info" style="margin-right: 20px" :underline="false">模板下载</el-link>
          <el-button type="info" @click="outerVisible = false" size="mini">取 消</el-button>
        </div>
      </el-dialog>
      <el-form-item label="日期">
        <el-date-picker
            style="width: 260px"
            v-model="formInline.vehDate"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getDate()">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="车型" style="padding-left: 8px">
        <el-select v-model="formInline.CarId" clearable placeholder="请选择车型" style="width: 170px">
          <el-option
              v-for="item in carNum"
              :key="item.value"
              :label="item.carNum"
              :value="item.carId">
            <span style="float: left">{{ item.carNum }}</span>
            <span style="float: right; color: #8492a6;">{{ item.carName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门">
        <el-select v-model="formInline.DepId" clearable placeholder="请选择部门" style="width: 120px">
          <el-option
              v-for="item in depData"
              :key="item.value"
              :label="item.depName"
              :value="item.depId">
            <span style="float: left">{{ item.depName }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit(formInline)">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
        :data="vehData"
        show-summary
        style="width: 100%; margin: auto"
        :cell-style="{padding: 0}">
      <el-table-column
          fixed
          prop="index"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="carNum"
          label="车牌">
      </el-table-column>
      <el-table-column
          prop="fromTime"
          label="出发时间"
          width="80">
      </el-table-column>
      <el-table-column
          prop="backTime"
          label="回程时间"
          width="80">
      </el-table-column>
      <el-table-column
          prop="useTime"
          label="用时"
          width="100">
      </el-table-column>
      <el-table-column
          prop="fromMileage"
          label="出发里程"
          width="80">
      </el-table-column>
      <el-table-column
          prop="backMileage"
          label="回程里程"
          width="80">
      </el-table-column>
      <el-table-column
          prop="useMileage"
          label="行程"
          width="80">
      </el-table-column>
      <el-table-column
          prop="carUser"
          label="使用人"
          width="120">
      </el-table-column>
      <el-table-column
          prop="useDate"
          label="日期"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button

              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "usedCar",
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      formInline: {
        vehDate: '',
        CarId: '',
        DepId: '',
      },
      vehData: [],
      carNum: [],
      depData: [],
      value: ''
    }
  },
  mounted() {
    this.getVehList()
    this.getCarNum()
    this.getDepList()
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSubmit(data) {
      var reqUrl
      if (data.length == "" || data == null) {
        reqUrl = '/api/veh/getVehList'
      }
      if (data.vehDate == null || data.vehDate.length == "") {
        if (data.CarId.length == "") {
          if (data.DepId.length == "") {
            reqUrl = '/api/veh/getVehList'
          } else reqUrl = '/api/veh/getVehList/' + 0 + '/' + data.DepId + '/' + 0 + '/' + 0
        } else if (data.DepId.length == "") {
          reqUrl = '/api/veh/getVehList/' + data.CarId + '/' + 0 + '/' + 0 + '/' + 0
        } else reqUrl = '/api/veh/getVehList/' + data.CarId + '/' + data.DepId + '/' + 0 + '/' + 0
      } else {
        if (data.CarId.length == "") {
          if (data.DepId.length == "") {
            reqUrl = '/api/veh/getVehList/' + 0 + '/' + 0 + '/' + data.vehDate[0] + '/' + data.vehDate[1]
          } else reqUrl = '/api/veh/getVehList/' + 0 + '/' + data.DepId + '/' + data.vehDate[0] + '/' + data.vehDate[1]
        } else if (data.DepId.length == "") {
          reqUrl = '/api/veh/getVehList/' + data.CarId + '/' + 0 + '/' + data.vehDate[0] + '/' + data.vehDate[1]
        } else reqUrl = '/api/veh/getVehList/' + data.CarId + '/' + data.DepId + '/' + data.vehDate[0] + '/' + data.vehDate[1]
      }
      this.$http({
        method: 'get',
        url: reqUrl,
        data: ''
      }).then((res)=>{
        this.vehData = res.data.data
      })
    },
    getCarNum() {
      this.$http({
        method: 'get',
        url: '/api/car/getCarList',
        data: []
      }).then((res)=>{
        this.carNum = res.data.data
      })
    },
    getVehList() {
      this.$http({
        method: 'get',
        url: '/api/veh/getVehList',
        data: ''
      }).then((res)=>{
        this.vehData = res.data.data
      })
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    getDepList() {
      this.$http({
        method: 'get',
        url: '/api/dep/getDepList',
        data: ''
      }).then((res)=>{
        this.depData = res.data.data
      })
    }
  }
};
</script>

<style scoped>
.el-table {
  overflow: visible !important;
}
</style>